<script setup>
import { computed, inject } from 'vue';
import { MText, useDisplay } from '@ca-crowdfunding/makuake-ui-n';
import LikeButton from '@/components/common/LikeButton';
import OwnerAvatar from '@/components/home/OwnerAvatar';
import ThumbnailCard from '@/components/home/ThumbnailCard';
import useGaEvent from '@/composables/common/gaEvent';
import { SELECT_CONTENT_TYPES } from '@/consts/ga';

const props = defineProps({
  hero: {
    type: Object,
    required: true,
  },
  index: {
    type: Number,
    required: true,
  },
});

const device = inject('device');
const prefix = computed(() => (device.value !== 'pc' ? 'sp_' : ''));
const parameterizedUrl = computed(
  () => `${props.hero.url}?from=${prefix.value}hero_image_${props.index + 1}`,
);

const { sendClickEvent, sendSelectContentEvent } = useGaEvent();
const { xs } = useDisplay();

const onClick = () => {
  sendClickEvent('project_hero', props.index);
  sendSelectContentEvent(props.hero.project.id, SELECT_CONTENT_TYPES.PROJECT);
};
</script>

<template>
  <a
    class="hero-item flex flex-col h-full"
    :href="parameterizedUrl"
    :target="hero.target"
    tabindex="-1"
    @click="onClick"
  >
    <article class="contents">
      <ThumbnailCard
        alt=""
        aspect-ratio="16 / 9"
        class="mb-4 sm:mb-6"
        eager
        height-sm="288"
        height="180"
        :src="hero.thumbnail_image_url"
        width-sm="512"
        width="320"
      />
      <div class="grow flex gap-2 sm:gap-3">
        <OwnerAvatar
          v-if="hero.project?.user?.image_url"
          :alt="hero.project.user.name"
          :is-recommended-owner="hero.project.user.is_selected_user"
          size="32"
          sm="48"
          :src="hero.project.user.image_url"
        />
        <div class="grow flex flex-col">
          <MText
            max-lines="3"
            class="mb-3 sm:mb-4 last:mb-0"
            size="x-small"
            sm="large"
            tag="h3"
            weight="bold"
          >
            {{ hero.title }}
          </MText>
          <div class="flex items-center justify-between mt-auto">
            <MText size="x-small" sm="large" tag="p" weight="bold">
              <span class="text-secondary">総額</span>
              {{
                hero.project?.collected_money?.toLocaleString('ja-JP', {
                  currency: 'JPY',
                  style: 'currency',
                })
              }}
            </MText>
            <LikeButton
              :index
              :size="xs ? 18 : 'large'"
              :project-id="hero.project?.id"
              tracking-id="hero"
            />
          </div>
        </div>
      </div>
    </article>
  </a>
</template>

<style lang="scss" scoped>
.hero-item:hover {
  text-decoration: none;

  &:not(:has(.like-button:hover)) .thumbnail-card {
    opacity: 0.72;
  }
}

.thumbnail-card {
  transition: opacity 0.2s;
}
</style>
