// TODO: リリース後 index.js をこのファイルに置き換える
import { createApp, ref } from 'vue';
import router from '@/router/home';
import VueGtag from 'vue-gtag-next';
// TODO: リリース後 Home.vue を HomeB.vue の内容に置き換える
import Home from '@/apps/HomeB';
import env from '@/modules/env';

import '@ca-crowdfunding/makuake-ui-n/dist/makuake-ui-n.css';

const app = createApp(Home);
app.use(router);
app.use(VueGtag, {
  property: { id: env.GOOGLE_ANALYTICS_ID },
  // コンソールにログを出力する
  // useDebugger: env.NODE_ENV === 'local',
});

// アプリケーション全体で保持するデータを提供する
// pinia への移行を検討する
app.provide('popularTags', ref([]));
app.provide('tags', ref([]));
app.provide('categories', ref([]));
app.provide('heading', ref(''));

app.mount('#vue-home');
