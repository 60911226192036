<script setup>
import { computed } from 'vue';
import ProjectSectionBase from '@/components/homeB/ProjectSectionBase';
import useIntersection from '@/composables/common/intersection';
import useFetchProjects from '@/composables/common/fetchProjects';
import { PROJECT_DISPLAY_NUMBER, TAGS } from '@/consts/home';

const props = defineProps({
  tag: { type: Object, required: true },
});

const label = computed(() =>
  (Object.keys(TAGS).find(TAG => TAGS[TAG] === props.tag) || '').toLowerCase(),
);

const { setupIntersection } = useIntersection();
const { fetchProjectsByTagIds, loading, projects } = useFetchProjects();

setupIntersection(() =>
  fetchProjectsByTagIds(props.tag.id, { perPage: PROJECT_DISPLAY_NUMBER }),
);
</script>

<template>
  <ProjectSectionBase
    :href="`/discover/tags/${tag.id}`"
    :projects
    :label
    :loading
    :title="tag.title"
  />
</template>
