<script setup>
import { MCol, MRow, MText } from '@ca-crowdfunding/makuake-ui-n';
import SectionHeader from '@/components/homeB/SectionHeader';
import useGaEvent from '@/composables/common/gaEvent';
import useEnvDefinition from '@/composables/envDefinition';
import useLink from '@/composables/link';
import { SELECT_CONTENT_TYPES } from '@/consts/ga';

const { env } = useEnvDefinition();
const { sendClickEvent, sendSelectContentEvent } = useGaEvent();
const { resolveLink } = useLink();

const cdn = env.URL_MAKUAKE_CDN;

const services = [
  {
    href: env.MAKUAKE_STORE_URL,
    name: 'Makuake STORE',
    slug: 'Store',
    text: 'Makuake発のユニークなアイデアがMakuake STOREで商品化。買い逃したあのプロジェクトにまた、出会えるかも。',
  },
  {
    href: 'https://magazine.makuake.com/',
    name: 'Makuake Magazine',
    slug: 'Magazine',
    text: 'アタラシイを生み出すための挑戦と応援をつなぐメディア。',
  },
  {
    href: 'https://mis.makuake.com/',
    name: 'MIS',
    slug: 'MIS',
    text: 'インキュベーションに「出口」を。Makuakeが伴走する新商品・新規事業開発。',
  },
];

const onClick = index => {
  const service = services[index];
  sendClickEvent('service', index);
  sendSelectContentEvent(
    service.slug.toLowerCase(),
    SELECT_CONTENT_TYPES.SERVICE,
  );
};
</script>

<template>
  <aside class="service-section">
    <SectionHeader title="Makuakeのサービス" />
    <div class="max-w-screen-lg mx-auto">
      <MRow gap="6">
        <MCol v-for="(service, key) of services" :key cols="12" md="4" sm="6">
          <a
            class="block"
            :href="service.href"
            :target="resolveLink(service.href).target"
            @click="onClick(key)"
          >
            <article>
              <h3>
                <picture>
                  <source
                    height="144"
                    media="(min-width: 960px)"
                    :srcset="`${cdn}img/home/bn_${service.slug}_pc.webp 1x, ${cdn}img/home/bn_${service.slug}_pc@2x.webp 2x`"
                    width="384"
                  />
                  <img
                    alt=""
                    class="mb-4 w-full"
                    height="124"
                    loading="lazy"
                    :src="`${cdn}img/home/bn_${service.slug}_sp.webp`"
                    width="366"
                  />
                </picture>
              </h3>
              <MText
                max-lines="3"
                size="x-small"
                sm="small"
                tag="h4"
                weight="bold"
              >
                {{ service.text }}
              </MText>
            </article>
          </a>
        </MCol>
      </MRow>
    </div>
  </aside>
</template>
