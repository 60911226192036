<script setup>
import { MChip, MIcon, MText } from '@ca-crowdfunding/makuake-ui-n';
import AppLink from '@/components/common/AppLink';
import ScrollContainer from '@/components/home/ScrollContainer';
import { SORT_OPTION_KEY_POPULAR } from '@/consts/discover';
import { ROUTES } from '@/consts/navigation';

const categories = [
  { label: 'プロダクト', slug: 'product' },
  { label: 'ファッション', slug: 'fashion' },
  { label: 'フード', slug: 'food' },
  { label: 'レストラン・バー', slug: 'restaurant' },
];
</script>

<template>
  <section
    class="category-section flex flex-wrap items-center gap-y-5 gap-x-8 md:gap-y-6 lg:justify-center"
  >
    <MText class="break-phrase" size="x-large" tag="h2" weight="bold">
      人気のカテゴリーから探す
    </MText>
    <ScrollContainer gap="2" gap-sm="4" tag="ul">
      <li v-for="(category, index) in categories" :key="index">
        <AppLink
          class="flex rounded-full"
          :index
          :to="{
            name: ROUTES.CATEGORY.name,
            params: { categorySlug: category.slug },
            query: { sort: SORT_OPTION_KEY_POPULAR },
          }"
          tracking-id="category_popular"
        >
          <MChip size="x-large" variant="outlined">
            <template #label>
              <MText
                class="truncate flex items-center"
                size="x-small"
                sm="small"
                weight="bold"
                tag="span"
              >
                {{ category.label }}
                <MIcon name="right" />
              </MText>
            </template>
          </MChip>
        </AppLink>
      </li>
    </ScrollContainer>
  </section>
</template>

<style scoped>
.break-phrase {
  word-break: auto-phrase;
}
</style>
