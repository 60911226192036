<script setup>
import { MSkeleton, MText } from '@ca-crowdfunding/makuake-ui-n';
import ProjectSectionBase from '@/components/homeB/ProjectSectionBase';
import useFetchProjects from '@/composables/common/fetchProjects';
import useIntersection from '@/composables/common/intersection';
import loggedInStatus from '@/modules/isLoggedinStatus';

const userLoggedIn = loggedInStatus.isUserLoggedin();
const { fetchRemindedProjects, loading, projects } = useFetchProjects();
const { setupIntersection } = useIntersection();

setupIntersection(fetchRemindedProjects);
</script>

<template>
  <ProjectSectionBase
    v-if="userLoggedIn"
    label="project-end"
    :loading
    :projects
    title="応援購入忘れはありませんか"
  >
    <template #[`project.loader.append`]>
      <MSkeleton class="mt-2" lines="0.5" size="2x-small" sm="x-small" />
    </template>
    <template #[`project.append`]="{ project }">
      <MText
        v-if="project.time_left_label"
        color="secondary"
        class="mt-2"
        size="2x-small"
        sm="x-small"
        >あと{{ project.time_left_label }}で終了</MText
      >
    </template>
  </ProjectSectionBase>
</template>
