<script setup>
import {
  MIcon,
  MSkeleton,
  MText,
  useDisplay,
} from '@ca-crowdfunding/makuake-ui-n';
import LikeButton from '@/components/common/LikeButton';
import OwnerAvatar from '@/components/homeB/OwnerAvatar';
import ThumbnailCard from '@/components/homeB/ThumbnailCard';
import useGaEvent from '@/composables/common/gaEvent';
import { SELECT_CONTENT_TYPES } from '@/consts/ga';

const props = defineProps({
  index: {
    type: Number,
  },
  loading: {
    type: Boolean,
    default: false,
  },
  project: {
    type: Object,
    default: null,
  },
  trackingId: {
    type: String,
  },
});

const { xs } = useDisplay();
const { sendClickEvent, sendSelectContentEvent } = useGaEvent();

const onClick = () => {
  sendClickEvent(`project_${props.trackingId}`, props.index);
  sendSelectContentEvent(props.project.id, SELECT_CONTENT_TYPES.PROJECT);
};
</script>

<template>
  <div v-if="loading" class="project-item-loader">
    <MSkeleton aspect-ratio="16 / 9" class="mb-4" rounded />
    <div class="flex gap-2 sm:gap-3">
      <MSkeleton size="32" sm="40" rounded="full" />
      <div class="flex flex-col grow">
        <MSkeleton class="mb-3 sm:mb-4" lines="2.4" size="x-small" sm="small" />
        <div class="flex items-center justify-between">
          <slot name="loader.subtext">
            <MSkeleton class="grow" lines="0.5" size="x-small" sm="small" />
          </slot>
          <MIcon
            class="shrink-0"
            color="disabled"
            name="heart"
            size="18"
            sm="medium"
          />
        </div>
        <slot name="loader.append" />
      </div>
    </div>
  </div>
  <a
    v-else-if="project"
    class="project-item flex flex-col h-full"
    :href="project.url"
    @click="onClick"
  >
    <article class="contents">
      <ThumbnailCard
        alt=""
        aspect-ratio="16 / 9"
        class="mb-4"
        height="126"
        height-md="162"
        :src="project.image_url"
        width="224"
        width-md="288"
      >
        <template #text>
          <slot name="thumbnail.append" />
        </template>
      </ThumbnailCard>
      <div class="grow flex gap-2 sm:gap-3">
        <slot>
          <OwnerAvatar
            v-if="project.user?.image_url"
            :alt="project.user.name"
            :is-selected-owner="project.user.is_selected_user"
            size="32"
            sm="40"
            :src="project.user.image_url"
          />
          <div class="grow flex flex-col">
            <MText
              class="mb-3 min-h-14 sm:mb-4 sm:min-h-16"
              max-lines="3"
              size="x-small"
              sm="small"
              tag="h4"
              weight="bold"
            >
              {{ project.title }}
            </MText>
            <div class="flex items-center justify-between mt-auto">
              <slot name="subtext" :project>
                <MText size="x-small" sm="small" tag="p" weight="bold">
                  <span class="text-secondary">総額</span>
                  {{
                    project.collected_money?.toLocaleString('ja-JP', {
                      currency: 'JPY',
                      style: 'currency',
                    })
                  }}
                </MText>
              </slot>
              <LikeButton
                :index
                :size="xs ? 18 : 'medium'"
                :project-id="project.id"
                :tracking-id
              />
            </div>
            <slot name="append" :project />
          </div>
        </slot>
      </div>
    </article>
  </a>
</template>

<style lang="scss" scoped>
.project-item:hover {
  text-decoration: none;

  &:not(:has(.like-button:hover)) .thumbnail-card {
    opacity: 0.72;
  }
}

.thumbnail-card {
  transition: opacity 0.2s;
}
</style>
