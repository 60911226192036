<script setup>
import { MChip, MText, useDisplay } from '@ca-crowdfunding/makuake-ui-n';
import AppLink from '@/components/common/AppLink';
import ScrollContainer from '@/components/common/ScrollContainer';
import RANKING_CATEGORIES from '@/consts/ranking';

defineProps({
  group: {
    type: String,
    default: RANKING_CATEGORIES[0].value,
  },
});

const { smAndDown } = useDisplay();
</script>

<template>
  <ScrollContainer class="ranking-tags gap-2 -mt-1 mb-5 py-1" tag="ul">
    <li v-for="(category, index) in RANKING_CATEGORIES" :key="category.value">
      <AppLink
        class="flex rounded-full"
        :tracking-id="`tag_group_${category.value}`"
        replace
        :to="{ query: { group: category.value } }"
      >
        <MChip
          :density="smAndDown ? 'compact' : 'default'"
          size="x-large"
          :variant="
            group === RANKING_CATEGORIES[index].value ? 'flat' : 'outlined'
          "
        >
          <template #label>
            <MText
              class="truncate md:px-2"
              color="current"
              size="x-small"
              md="small"
              weight="bold"
              tag="span"
            >
              {{ category.label }}
            </MText>
          </template>
        </MChip>
      </AppLink>
    </li>
  </ScrollContainer>
</template>
