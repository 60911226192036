<script setup>
import { computed } from 'vue';
import {
  MIcon,
  MSkeleton,
  MText,
  useDisplay,
} from '@ca-crowdfunding/makuake-ui-n';
import LikeButton from '@/components/common/LikeButton';
import RankLabel from '@/components/common/RankLabel';
import OwnerAvatar from '@/components/home/OwnerAvatar';
import ThumbnailCard from '@/components/home/ThumbnailCard';
import useReturnPrice from '@/composables/common/returnPrice';

const props = defineProps({
  loading: {
    type: Boolean,
    default: false,
  },
  project: {
    type: Object,
  },
  rank: {
    type: Number,
  },
});

const { xs, mdAndUp } = useDisplay();
const { getMinPriceOfReturns } = useReturnPrice();

const isRowLayout = computed(
  () => mdAndUp.value || (props.rank === 1 && xs.value),
);
</script>

<template>
  <div v-if="loading" class="flex" :class="{ 'flex-col': isRowLayout }">
    <MSkeleton
      aspect-ratio="16 / 9"
      :class="isRowLayout ? 'mb-4' : 'mr-3 max-w-36'"
    />
    <div class="grow flex gap-2">
      <MSkeleton v-if="isRowLayout" rounded="full" size="32" sm="40" />
      <div class="grow">
        <MSkeleton
          class="mb-3 sm:mb-4"
          :lines="isRowLayout ? 2 : 2.4"
          :size="isRowLayout ? 'small' : '2x-small'"
        />
        <div class="flex items-center justify-between">
          <MSkeleton
            class="grow"
            lines="0.5"
            :size="isRowLayout ? 'small' : '2x-small'"
          />
          <MIcon
            class="shrink-0"
            color="disabled"
            name="heart"
            :size="isRowLayout ? 'medium' : 'small'"
          />
        </div>
      </div>
    </div>
  </div>
  <a
    v-else-if="project"
    class="project-item flex h-full"
    :class="{ 'flex-col': isRowLayout }"
    :href="project.url"
  >
    <article class="contents">
      <ThumbnailCard
        v-if="project.image_url"
        aspect-ratio="16 / 9"
        class="min-w-36"
        :class="isRowLayout ? 'mb-4' : 'mr-3'"
        :height="isRowLayout ? 315 : 126"
        height-sm="252"
        :src="project.image_url"
        :width="isRowLayout ? 560 : 224"
        width-sm="448"
      >
        <template #text>
          <RankLabel :rank />
        </template>
      </ThumbnailCard>
      <div class="flex grow gap-3">
        <OwnerAvatar
          v-if="isRowLayout && project.user?.image_url"
          :alt="project.user.name"
          :is-recommended-owner="project.user.is_selected_user"
          size="32"
          sm="40"
          :src="project.user.image_url"
        />
        <div class="flex flex-col grow">
          <MText
            class="mb-3 sm:mb-4"
            :class="{ 'min-h-[2.4375rem]': isRowLayout }"
            :max-lines="isRowLayout ? 2 : 3"
            :size="isRowLayout ? 'small' : '2x-small'"
            tag="h3"
            weight="bold"
          >
            {{ project.title }}
          </MText>
          <div class="flex items-center justify-between mt-auto">
            <MText
              :size="isRowLayout ? 'small' : '2x-small'"
              tag="p"
              weight="bold"
            >
              <span v-if="getMinPriceOfReturns(project.returns)"
                >{{
                  getMinPriceOfReturns(project.returns).toLocaleString(
                    'ja-JP',
                    {
                      currency: 'JPY',
                      style: 'currency',
                    },
                  )
                }}〜</span
              >
              <span v-else class="text-secondary">{{
                RETURN_SUSPENDED_TEXT
              }}</span>
            </MText>
            <LikeButton
              :size="isRowLayout ? 'medium' : 'small'"
              :project-id="project.id"
            />
          </div>
        </div>
      </div>
    </article>
  </a>
</template>

<style lang="scss" scoped>
.project-item:hover {
  text-decoration: none;

  &:not(:has(.like-button:hover)) .thumbnail-card {
    opacity: 0.72;
  }
}

.thumbnail-card {
  transition: opacity 0.2s;
}

.min-h-\[2\.4375rem\] {
  min-height: 2.4375rem; /* 39px */
}
</style>
