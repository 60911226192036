<script setup>
import { computed, ref, onMounted, watch } from 'vue';
import { useDisplay } from '@ca-crowdfunding/makuake-ui-n';
import ProjectItem from '@/components/discover/ProjectItemForDiscover';
import useIntersection from '@/composables/common/intersection';
import { PER_PAGE } from '@/consts/discover';

const props = defineProps({
  itemWidth: {
    type: String,
  },
  loading: {
    type: Boolean,
    default: false,
  },
  perPage: {
    type: Number,
    default: PER_PAGE,
  },
  projects: {
    type: Array,
    required: true,
  },
});

const emit = defineEmits(['fetch']);

const pagination = defineModel('pagination', {
  type: Object,
  required: true,
});

const hasMore = ref(true);
const target = ref(null);
const { setupIntersection } = useIntersection();

setupIntersection(
  async () => {
    pagination.value.page += 1;
    emit('fetch');
  },
  {
    infinity: true,
    target,
  },
);

onMounted(() => {
  pagination.value.page = 1;
  emit('fetch');
});

watch(pagination, v => {
  hasMore.value = v.total > v.per_page * v.page;
});

const { sm, xs } = useDisplay();

const width = computed(() => {
  if (props.itemWidth) return props.itemWidth;
  if (xs.value) return '144px';
  if (sm.value) return '224px';
  return '280px';
});
</script>

<template>
  <ul class="project-list grid gap-x-4 gap-y-6 sm:gap-x-6">
    <li v-for="(project, key) in projects" :key>
      <slot name="project" :project>
        <ProjectItem
          :index="key"
          :project
          :no-avatar="xs"
          tracking-id="project"
        >
          <template #[`thumbnail.append`]>
            <slot name="project.thumbnail.append" :project />
          </template>
          <template #subtext>
            <slot name="project.subtext" :project />
          </template>
          <template #append>
            <slot name="project.append" :project />
          </template>
        </ProjectItem>
      </slot>
    </li>
    <template v-if="loading">
      <li v-for="key in perPage" :key>
        <slot name="loading" :order="key">
          <ProjectItem loading>
            <template #[`loader.subtext`]>
              <slot name="project.loader.subtext" />
            </template>
            <template #[`loader.append`]>
              <slot name="project.loader.append" />
            </template>
          </ProjectItem>
        </slot>
      </li>
    </template>
    <li v-show="hasMore" ref="target"></li>
  </ul>
</template>

<style scoped>
.project-list {
  grid-template-columns: repeat(auto-fill, minmax(v-bind(width), 1fr));
}
</style>
