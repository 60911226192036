<script setup>
import { computed, inject } from 'vue';
import ThumbnailCard from '@/components/homeB/ThumbnailCard';
import useGaEvent from '@/composables/common/gaEvent';
import { SELECT_CONTENT_TYPES } from '@/consts/ga';

const props = defineProps({
  banner: {
    type: Object,
    required: true,
  },
  index: {
    type: Number,
    required: true,
  },
});

const device = inject('device');
const prefix = computed(() => (device.value !== 'pc' ? 'sp_' : ''));
const parameterizedUrl = computed(
  () => `${props.banner.url}?from=${prefix.value}hero_image_${props.index + 1}`,
);

const { sendClickEvent, sendSelectContentEvent } = useGaEvent();

const onClick = () => {
  sendClickEvent('banner', props.index);
  sendSelectContentEvent(
    `banner_${props.banner.id}`,
    SELECT_CONTENT_TYPES.ARTICLE,
  );
};
</script>

<template>
  <a
    class="banner-item block"
    :href="parameterizedUrl"
    :target="banner.target"
    tabindex="-1"
    @click="onClick"
  >
    <h3>
      <ThumbnailCard
        :alt="banner.title"
        aspect-ratio="3 / 1"
        eager
        height-sm="107"
        height="80"
        :src="banner.thumbnail_image_url"
        width-sm="320"
        width="240"
      />
    </h3>
  </a>
</template>
