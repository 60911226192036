<script setup>
import { fromUnixTime, isPast } from 'date-fns';
import {
  MIcon,
  MSkeleton,
  MText,
  useDisplay,
} from '@ca-crowdfunding/makuake-ui-n';
import LikeButton from '@/components/common/LikeButton';
import ProjectLabel from '@/components/common/ProjectLabel';
import OwnerAvatar from '@/components/home/OwnerAvatar';
import ThumbnailCard from '@/components/home/ThumbnailCard';
import useGaEvent from '@/composables/common/gaEvent';
import { SELECT_CONTENT_TYPES } from '@/consts/ga';

const props = defineProps({
  index: {
    type: Number,
  },
  loading: {
    type: Boolean,
    default: false,
  },
  noAvatar: {
    type: Boolean,
    default: false,
  },
  project: {
    type: Object,
    default: null,
  },
  trackingId: {
    type: String,
  },
});

const { xs } = useDisplay();
const { sendClickEvent, sendSelectContentEvent } = useGaEvent();

const onClick = () => {
  sendClickEvent(props.trackingId, props.index);
  sendSelectContentEvent(props.project.id, SELECT_CONTENT_TYPES.PROJECT);
};
</script>

<template>
  <template v-if="loading">
    <MSkeleton aspect-ratio="16 / 9" class="mb-4" />
    <div class="flex gap-2">
      <MSkeleton
        v-if="!noAvatar"
        class="hidden sm:block"
        rounded="full"
        size="32"
        sm="40"
      />
      <div class="grow">
        <MSkeleton
          class="mb-3 sm:mb-4"
          :lines="xs ? 2.4 : 2"
          size="2x-small"
          sm="small"
        />
        <div class="flex items-center justify-between">
          <slot name="loader.subtext">
            <MSkeleton class="grow" lines="0.5" size="2x-small" sm="small" />
          </slot>
          <MIcon
            class="shrink-0"
            color="disabled"
            name="heart"
            size="small"
            sm="medium"
          />
        </div>
        <slot name="loader.append" />
      </div>
    </div>
  </template>
  <a
    v-else-if="project"
    class="project-item"
    :href="project.url"
    @click="onClick"
  >
    <article class="contents">
      <ThumbnailCard
        v-if="project.image_url"
        aspect-ratio="16 / 9"
        class="mb-4"
        height="126"
        height-sm="252"
        :src="project.image_url"
        width="224"
        width-sm="448"
      >
        <template #text>
          <slot name="thumbnail.append" :project />
        </template>
      </ThumbnailCard>
      <div class="flex gap-3">
        <OwnerAvatar
          v-if="!noAvatar && project.user?.image_url"
          :alt="project.user.name"
          :is-recommended-owner="project.user.is_selected_user"
          size="32"
          sm="40"
          :src="project.user.image_url"
        />
        <div class="grow">
          <slot name="title" :project>
            <MText
              class="mb-3 sm:mb-4"
              :class="xs ? 'min-h-[3.1875rem]' : 'min-h-[2.4375rem]'"
              :max-lines="xs ? 3 : 2"
              size="2x-small"
              sm="small"
              tag="h3"
              weight="bold"
            >
              {{ project.title }}
            </MText>
          </slot>
          <div class="flex items-center justify-between">
            <slot name="subtext" :project>
              <ProjectLabel v-if="project.is_new_store_opening" store />
              <ProjectLabel
                v-else-if="isPast(fromUnixTime(project.expiration_date))"
                finished
              />
              <MText v-else size="2x-small" sm="small" tag="p" weight="bold">
                <span class="text-secondary">総額</span>
                {{
                  project.collected_money?.toLocaleString('ja-JP', {
                    currency: 'JPY',
                    style: 'currency',
                  })
                }}
              </MText>
            </slot>
            <LikeButton
              :index
              :project-id="project.id"
              :size="xs ? 'small' : 'medium'"
            />
          </div>
          <slot name="append" :project />
        </div>
      </div>
    </article>
  </a>
</template>

<style lang="scss" scoped>
.project-item:hover {
  text-decoration: none;

  &:not(:has(.like-button:hover)) .thumbnail-card {
    opacity: 0.72;
  }
}

.thumbnail-card {
  transition: opacity 0.2s;
}

.min-h-\[2\.4375rem\] {
  min-height: 2.4375rem; /* 39px */
}

.min-h-\[3\.1875rem\] {
  min-height: 3.1875rem; /* 51px */
}
</style>
