<script setup>
import { MText } from '@ca-crowdfunding/makuake-ui-n';
import SectionHeader from '@/components/homeB/SectionHeader';
import useEnvDefinition from '@/composables/envDefinition';
import useGaEvent from '@/composables/common/gaEvent';
import { SELECT_CONTENT_TYPES } from '@/consts/ga';

const { sendClickEvent, sendSelectContentEvent } = useGaEvent();
const { env } = useEnvDefinition();

const cdn = env.URL_MAKUAKE_CDN;

const onClick = () => {
  sendClickEvent('guide');
  sendSelectContentEvent('guide', SELECT_CONTENT_TYPES.ARTICLE);
};
</script>

<template>
  <section class="introduction-section">
    <SectionHeader title="Makuakeの紹介" />
    <div class="flex flex-wrap items-center justify-center gap-4 sm:gap-6">
      <MText size="small" sm="medium">
        <span
          >Makuakeはまだ世の中にないものやストーリーあふれるチャレンジが<br
            class="hidden sm:inline"
          />集まる「<strong>アタラシイものや体験の応援購入サービス</strong>」です。</span
        >
      </MText>
      <a href="/guide" @click="onClick">
        <h3>
          <picture>
            <source
              height="160"
              media="(min-width: 640px)"
              :srcset="`${cdn}img/home/bn_guide_pc.webp 1x, ${cdn}img/home/bn_guide_pc@2x.webp 2x`"
              width="588"
            />
            <img
              alt="Makuakeはじめてガイド"
              class="w-full"
              height="124"
              loading="lazy"
              :src="`${cdn}img/home/bn_guide_sp.webp`"
              width="366"
            />
          </picture>
        </h3>
      </a>
    </div>
  </section>
</template>
