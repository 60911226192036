<script setup>
import { computed, watch } from 'vue';
import { Vue3Lottie } from 'vue3-lottie';
import { MButton } from '@ca-crowdfunding/makuake-ui-n';
import useGaEvent from '@/composables/common/gaEvent';
import useLikeStateManager from '@/composables/common/likeStateManager';

const props = defineProps({
  index: {
    type: Number,
  },
  projectId: {
    type: Number,
    required: true,
  },
  size: {
    type: [String, Number],
    default: 'medium',
  },
  trackingId: {
    type: String,
  },
});

const { sendAddToLikeListEvent, sendClickEvent } = useGaEvent();
const { handleClick, initState, isLiked, likeJson, lottieRef } =
  useLikeStateManager();

watch(
  () => props.projectId,
  () => initState(props.projectId),
  { immediate: true },
);

const sizes = {
  small: 4,
  medium: 6,
  large: 7,
};

const isNumberishSize = computed(
  () => typeof props.size === 'number' || /^\d+$/.test(props.size),
);

const classNames = computed(() =>
  isNumberishSize.value
    ? ''
    : [`h-${sizes[props.size] * 2}`, `w-${sizes[props.size] * 2}`],
);

const lottieStyle = computed(() =>
  isNumberishSize.value
    ? {
        height: `${Number(props.size) / 8}rem`,
        width: `${Number(props.size) / 8}rem`,
      }
    : '',
);

const wrapperClassNames = computed(() =>
  isNumberishSize.value
    ? ''
    : [`h-${sizes[props.size]}`, `w-${sizes[props.size]}`],
);

const wrapperStyle = computed(() =>
  isNumberishSize.value
    ? {
        minHeight: `${Number(props.size) / 16}rem`,
        minWidth: `${Number(props.size) / 16}rem`,
      }
    : '',
);

/**
 * 気になるボタンのクリックイベントハンドラーに渡すコールバック関数（成功時）
 *
 * @param {boolean} status - 成功後の気になる状態（true: 気になる登録された, false: 気になる解除された）
 */
const onSuccess = status => {
  // 気になる登録時 GA に専用のイベントを送信する
  if (status) sendAddToLikeListEvent(props.projectId);
};

const onClick = () => {
  const prefix = isLiked.value ? 'unlike' : 'like';
  sendClickEvent(
    `${prefix}${props.trackingId ? `_${props.trackingId}` : ''}`,
    props.index,
  );
  handleClick(props.projectId, { onSuccess });
};
</script>

<template>
  <div
    class="shrink-0 flex items-center justify-center"
    :class="wrapperClassNames"
    :style="wrapperStyle"
  >
    <MButton
      :aria-label="isLiked ? '気になる解除' : '気になる'"
      class="like-button"
      color="disabled"
      rounded="full"
      variant="text"
      @click.stop.prevent="onClick"
    >
      <template #label>
        <Vue3Lottie
          ref="lottieRef"
          class="pointer-events-none"
          :class="classNames"
          :animation-data="likeJson"
          :auto-play="false"
          :loop="false"
          :style="lottieStyle"
        />
      </template>
    </MButton>
  </div>
</template>

<style lang="scss" scoped>
.like-button {
  height: 3rem;
  margin: -100%;
  min-width: 3rem;
  padding: 0;
}
</style>
